import "./App.css";
import illustration from "./assets/img/ilustration.svg";
import { TypeAnimation } from "react-type-animation";

function App() {
  return (
    <>
      <section className="main">
        <div className="all">
          <div className="container">
            <nav>
              <div className="logo">
                <h2>Technico</h2>
              </div>
            </nav>
            <div className="row align-items-center wrappers">
              <div className="col-md-6">
                <div className="text">
                  <span>Uskoro online</span>
                  <h1>
                    Technico radi nešto
                    <TypeAnimation
                      cursor={true}
                      sequence={[
                        "Novo.",
                        1000,
                        "Inovativno.",
                        1000,
                        "WOW.",
                        2000,
                      ]}
                    />
                  </h1>
                  <p>
                    Mi smo digitalna agencija sa uredom u Splitu. Naš cilj je
                    digitalizacija Vašeg poslovanja na veći nivo, uz pametan
                    pristup i kvalitetan tim generirati veliki promet vaše web
                    stranice, društvenih mreža itd.
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <img
                  style={{ width: "100%" }}
                  src={illustration}
                  alt="Working man illustration"
                />
              </div>
            </div>
            <div className="info">
              <a href="https://goo.gl/maps/31w5mW52ZC83ergS8">Lokacija</a>
              <a href="mailto:info@technico.io">Kontakt</a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default App;
